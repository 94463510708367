import { rootCssString } from "nft/css/cssStringFromTheme";
import { PropsWithChildren, useMemo } from "react";
import {
  createGlobalStyle,
  css,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components";
import { useIsDarkMode } from "theme/components/ThemeToggle";
import { getAccent2, getNeutralContrast } from "theme/utils";

import { navDimensions } from "../nft/css/sprinkles.css";
import { darkTheme, lightTheme, ThemeColors } from "./colors";
import { darkDeprecatedTheme, lightDeprecatedTheme } from "./deprecatedColors";

export const MEDIA_WIDTHS = {
  deprecated_upToExtraSmall: 500,
  deprecated_upToSmall: 720,
  deprecated_upToMedium: 960,
  deprecated_upToLarge: 1280,
};

const MAX_CONTENT_WIDTH = "1200px";

const deprecated_mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((acc, size) => {
  acc[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return acc;
}, {} as any);

export const BREAKPOINTS = {
  xs: 396,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
  xxxl: 1920,
};

// deprecated - please use the ones in styles.ts file
const transitions = {
  duration: {
    slow: "500ms",
    medium: "250ms",
    fast: "125ms",
  },
  timing: {
    ease: "ease",
    in: "ease-in",
    out: "ease-out",
    inOut: "ease-in-out",
  },
};

const opacities = {
  hover: 0.6,
  click: 0.4,
  disabled: 0.5,
  enabled: 1,
};

const blurs = {
  light: "blur(12px)",
};

const fonts = {
  code: "courier, courier new, serif",
};

const gapValues = {
  xs: "4px",
  sm: "8px",
  md: "12px",
  lg: "24px",
  xl: "32px",
};
export type Gap = keyof typeof gapValues;

function getSettings(darkMode: boolean) {
  return {
    darkMode,
    grids: gapValues,
    fonts,

    // shadows
    shadow1: darkMode ? "#000" : "#2F80ED",

    // media queries
    deprecated_mediaWidth: deprecated_mediaWidthTemplates,

    navHeight: navDimensions.height,
    navVerticalPad: navDimensions.verticalPad,
    mobileBottomBarHeight: 48,
    maxWidth: MAX_CONTENT_WIDTH,

    // deprecated - please use hardcoded exported values instead of
    // adding to the theme object
    breakpoint: BREAKPOINTS,
    transition: transitions,
    blur: blurs,
    opacity: opacities,
    text: {
      heading: {
        fontFamily: "inherit",
        fontWeight: 485,
      },
    },
  };
}

// eslint-disable-next-line import/no-unused-modules -- used in styled.d.ts
export function getTheme(
  darkMode: boolean,
  overriddenColors?: Partial<ThemeColors>
) {
  const [colors, deprecatedColors] = darkMode
    ? [darkTheme, darkDeprecatedTheme]
    : [lightTheme, lightDeprecatedTheme];
  const colorsWithOverrides = applyOverriddenColors(colors, overriddenColors);

  return {
    ...colorsWithOverrides,
    ...deprecatedColors,
    ...getSettings(darkMode),
    glueTest: "green",
    glue: {
      green: {
        "50": "#E6FEEE",
        "100": "#99FFBA", // Super Light Green
        "200": "#85E8A5", // Light Green
        "300": "#5FFB92",
        "400": "#33FA73",
        "500": "#00FF52", // Hover Green
        "600": "#05DB4A", // Green
        "700": "#00C736", // Dark Green
        "800": "#02B80A",
        "900": "#39453F",
      },
      gray: {
        "50": "#F2F4F7", // Light gray
        "100": "#F6F7FF", // Gray
        "200": "#BBC2DA", // Medium Gray
        "300": "#9DA5BE",
        "400": "#8993B1", // Dark Gray
        "500": "#65729A",
        "600": "#363840",
        "700": "#3D455C",
        "800": "#292E3D",
        "900": "#14171F",
      },
      red: {
        "50": "#FFE5E6",
        "100": "#FFB8B8",
        "200": "#FF8A8A",
        "300": "#FF5C5D",
        "400": "#FF2E2F",
        "500": "#FF3536", // Red
        "600": "#CC0001",
        "700": "#990001",
        "800": "#660001",
        "900": "#330000",
      },
      orange: {
        "50": "#FFF3E5",
        "100": "#FFDFB8",
        "200": "#FFCA8A",
        "300": "#FFB55C",
        "400": "#FFA02E",
        "500": "#FF8B00", // Orange
        "600": "#CC6F00",
        "700": "#995300",
        "800": "#663800",
        "900": "#331C00",
      },
      yellow: {
        "50": "#FEFBE7",
        "100": "#FCF2BB",
        "200": "#FAEA8F",
        "300": "#F8E263",
        "400": "#F6DC41", // Yellow
        "500": "#F3D20C",
        "600": "#C3A809",
        "700": "#927E07",
        "800": "#615405",
        "900": "#312A02",
      },
      blue: {
        "50": "#F6F8FC",
        "100": "#BFE4F8",
        "200": "#96D3F3",
        "300": "#6DC2EE",
        "400": "#44B1E9",
        "500": "#1BA2E8", // Blue
        "600": "#1891D0", // Dark Blue
        "700": "#106089",
        "800": "#0B405B",
        "900": "#05202E",
      },
      purple: {
        "50": "#F2EAFA",
        "100": "#DBC5F1",
        "200": "#C5A0E9",
        "300": "#AE7BE0",
        "400": "#9756D7",
        "500": "#8D38E3", // Purple
        "600": "#762DBE", // Dark Purple
        "700": "#4D1D7C",
        "800": "#331452",
        "900": "#1A0A29",
      },
      lightGreen: {
        "50": "#f0fdf4",
        "100": "#dcfce7",
        "200": "#DBFFEC",
        "500": "#CDF3DA",
      },
    },
    accent1: "#05DB4A", // same as glue.green.600
    accent2: "#E6FEEE" // same as glue.green.50
  };
}

function applyOverriddenColors(
  defaultColors: ThemeColors,
  overriddenColors?: Partial<ThemeColors>
) {
  if (!overriddenColors) return defaultColors;

  // Remove any undefined values from the object such that no theme values are overridden by undefined
  const definedOverriddenColors = Object.keys(overriddenColors).reduce(
    (acc, curr) => {
      const key = curr as keyof ThemeColors;
      if (overriddenColors[key] !== undefined) acc[key] = overriddenColors[key];
      return acc;
    },
    {} as Partial<ThemeColors>
  );

  const mergedColors = { ...defaultColors, ...definedOverriddenColors };

  // Since accent2 is derived from accent1 and surface1, it needs to be recalculated if either are overridden
  if (
    (overriddenColors.accent1 || overriddenColors.surface1) &&
    !overriddenColors.accent2
  ) {
    mergedColors.accent2 = getAccent2(
      mergedColors.accent1,
      mergedColors.surface1
    );
  }
  // neutralContrast should be updated to contrast against accent1 if accent1 is overridden
  if (overriddenColors.accent1 && !overriddenColors.neutralContrast) {
    mergedColors.neutralContrast = getNeutralContrast(mergedColors.accent1);
  }

  return mergedColors;
}

export function ThemeProvider({
  children,
  ...overriddenColors
}: PropsWithChildren<Partial<ThemeColors>>) {
  const darkMode = useIsDarkMode();
  // eslint-disable-next-line react-hooks/exhaustive-deps -- only update when darkMode or overriddenColors' entries change
  const themeObject = useMemo(
    () => getTheme(darkMode, overriddenColors),
    [darkMode, JSON.stringify(overriddenColors)]
  );

  console.log(themeObject);

  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

export const ThemedGlobalStyle = createGlobalStyle`
  html {
    color: ${({ theme }) => theme.neutral1};
    background-color: ${({ theme }) => theme.background} !important;
  }

 summary::-webkit-details-marker {
    display:none;
  }

  a {
    color: ${({ theme }) => theme.accent1}; 
  }

  :root {
    ${({ theme }) => rootCssString(theme.darkMode)}
  }
`;
