import { SVGProps } from "react"

const MediumLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props.fill ?? "#8993B1"}
        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm6.857 6.796-1.093 1.047a.317.317 0 0 0-.123.305v7.707a.313.313 0 0 0 .123.305l1.072 1.047v.233h-5.379v-.222l1.106-1.074c.11-.11.11-.142.11-.306V9.603l-3.08 7.81h-.415l-3.584-7.81v5.236a.713.713 0 0 0 .2.6l1.442 1.744v.233H5.143v-.233l1.44-1.744a.7.7 0 0 0 .188-.6V8.786a.52.52 0 0 0-.174-.448l-1.28-1.542v-.234h3.978l3.07 6.737 2.702-6.731h3.79v.228Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default MediumLogo
