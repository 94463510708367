import { SVGProps } from "react"

const GlueLogoWithBackgroundRounded = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <rect width={40} height={40} fill="#05DB4A" rx={8} />
      <path fill="#05DB4A" d="M40 0H0v40h40V0Z" />
      <path
        fill="#000"
        d="M31.387 11.515C29.122 9.248 26.203 8.065 22.99 8h-8.064C9.952 8.066 6 12.044 6 17.014s4.042 9.014 9.01 9.014h7.98c3.323 0 5.941-2.704 5.941-6.029a6.034 6.034 0 0 0-6.026-6.029h-8.14l-1.181 4.537h9.321a1.493 1.493 0 0 1 0 2.984h-7.98c-2.467 0-4.39-2.008-4.39-4.477 0-2.469 1.919-4.437 4.39-4.477h8.06c4.113 0 7.375 3.347 7.375 7.463s-3.346 7.463-7.46 7.463H11.914L10.733 32H22.99c3.195-.06 6.132-1.248 8.397-3.515A11.923 11.923 0 0 0 34.9 20c0-3.206-1.247-6.218-3.513-8.485Z"
      />
    </g>
    <rect
      width={39.25}
      height={39.25}
      x={0.375}
      y={0.375}
      stroke="#05DB4A"
      strokeWidth={0.75}
      rx={7.625}
    />
    <defs>
      <clipPath id="a">
        <rect width={40} height={40} fill="#fff" rx={8} />
      </clipPath>
    </defs>
  </svg>
)
export default GlueLogoWithBackgroundRounded
