import { SVGProps } from "react"

const YoutubeLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props.fill ?? "#8993B1"}
        d="m9.714 16.143 6.302-3.643-6.302-3.643v7.286Zm14.037-9.508c.158.57.268 1.336.34 2.307.085.972.122 1.81.122 2.538l.073 1.02c0 2.66-.195 4.614-.535 5.865-.303 1.093-1.007 1.797-2.1 2.1-.571.159-1.615.268-3.218.34a80.932 80.932 0 0 1-4.36.122l-1.93.073c-5.088 0-8.257-.194-9.508-.534-1.093-.304-1.797-1.008-2.1-2.101-.159-.57-.268-1.336-.34-2.307a29.218 29.218 0 0 1-.122-2.538L0 12.5c0-2.66.194-4.614.534-5.865.304-1.093 1.008-1.797 2.101-2.1.57-.159 1.615-.268 3.218-.34a80.931 80.931 0 0 1 4.36-.122L12.142 4c5.088 0 8.257.194 9.508.534 1.093.304 1.797 1.008 2.1 2.101Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default YoutubeLogo
