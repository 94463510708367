import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Protocol } from "@uniswap/router-sdk";
import { sendAnalyticsEvent } from "analytics";
import { isUniswapXSupportedChain } from "constants/chains";
import ms from "ms";
import { logSwapQuoteRequest } from "tracing/swapFlowLoggers";
import { trace } from "tracing/trace";

import {
  GetQuoteArgs,
  INTERNAL_ROUTER_PREFERENCE_PRICE,
  QuoteIntent,
  QuoteMethod,
  QuoteState,
  RouterPreference,
  RoutingConfig,
  TradeResult,
  URAQuoteResponse,
  URAQuoteType,
} from "./types";
import { isExactInput, transformQuoteToTrade } from "./utils";

const UNISWAP_API_URL = process.env.REACT_APP_UNISWAP_API_URL;
const UNISWAP_GATEWAY_DNS_URL = process.env.REACT_APP_UNISWAP_GATEWAY_DNS;
if (UNISWAP_API_URL === undefined || UNISWAP_GATEWAY_DNS_URL === undefined) {
  throw new Error(
    `UNISWAP_API_URL and UNISWAP_GATEWAY_DNS_URL must be defined environment variables`
  );
}

const CLIENT_PARAMS = {
  protocols: [Protocol.V3],
};

export const routingApi = createApi({
  reducerPath: "routingApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (build) => ({
    getQuote: build.query<TradeResult, GetQuoteArgs>({
      queryFn(args, _api, _extraOptions, fetch) {
        return trace(
          { name: "Quote", op: "quote", data: { ...args } },
          async (trace) => {
            logSwapQuoteRequest(
              args.tokenInChainId,
              args.routerPreference,
              false
            );
            try {
              return trace.child(
                { name: "Quote on client", op: "quote.client" },
                async (clientTrace) => {
                  const { getRouter, getClientSideQuote } = await import(
                    "lib/hooks/routing/clientSideSmartOrderRouter"
                  );
                  const router = getRouter(args.tokenInChainId);
                  const quoteResult = await getClientSideQuote(
                    args,
                    router,
                    CLIENT_PARAMS
                  );
                  if (quoteResult.state === QuoteState.SUCCESS) {
                    const trade = await transformQuoteToTrade(
                      args,
                      quoteResult.data,
                      QuoteMethod.CLIENT_SIDE_FALLBACK
                    );
                    return {
                      data: { ...trade, latencyMs: trace.now() },
                    };
                  } else {
                    clientTrace.setStatus("not_found");
                    trace.setStatus("not_found");
                    return { data: { ...quoteResult, latencyMs: trace.now() } };
                  }
                }
              );
            } catch (error: any) {
              console.warn(`GetQuote failed on client: ${error}`);
              trace.setError(error);
              return {
                error: {
                  status: "CUSTOM_ERROR",
                  error: error?.detail ?? error?.message ?? error,
                },
              };
            }
          }
        );
      },
      keepUnusedDataFor: ms(`10s`),
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
});

export const { useGetQuoteQuery } = routingApi;
export const useGetQuoteQueryState =
  routingApi.endpoints.getQuote.useQueryState;
