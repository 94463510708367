import styled from 'styled-components'

import HolidayUniIcon from './HolidayUniIcon'

// ESLint reports `fill` is missing, whereas it exists on an SVGProps type
export type SVGProps = React.SVGProps<SVGSVGElement> & {
  fill?: string
  height?: string | number
  width?: string | number
  gradientId?: string
  clickable?: boolean
}

export const UniIcon = ({ clickable, ...props }: SVGProps) => (
  <Container clickable={clickable}>
    <svg width="80" height="24" viewBox="0 0 80 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4380_37545)">
      <path d="M41.2334 11.0705C41.2334 9.48635 42.2324 8.36434 43.6942 8.36434C45.1561 8.36434 46.2041 9.48551 46.2041 11.0705C46.2041 12.6555 45.1561 13.8494 43.7187 13.8494C42.2814 13.8494 41.2334 12.6792 41.2334 11.0705ZM41.2824 17.6522L38.4068 18.7489C39.0647 20.8696 40.9657 22.1614 43.6216 22.1614C47.6667 22.1614 49.8354 19.2119 49.8354 13.7269C49.8354 8.24183 47.6912 5.43848 43.7441 5.43848C40.3331 5.43848 37.9204 7.82783 37.9204 11.1668C37.9204 14.5059 40.1136 16.6274 43.1833 16.6274C44.548 16.6274 45.7176 16.1644 46.5942 15.3355C46.5453 17.8466 45.4491 19.2846 43.5971 19.2846C42.4275 19.2846 41.599 18.6999 41.2824 17.6514V17.6522Z" fill="black"/>
      <path d="M54.6101 1.44141H51.1992V18.0182H54.6101V1.44141Z" fill="black"/>
      <path d="M61.6766 18.4087C64.8688 18.4087 67.0374 16.5068 67.0374 13.7035V5.8291H63.6265V13.5083C63.6265 14.8247 62.9442 15.5563 61.6774 15.5563C60.4107 15.5563 59.7283 14.8247 59.7283 13.5083V5.8291H56.3174V13.7035C56.3174 16.6285 58.4861 18.4087 61.6783 18.4087H61.6766Z" fill="black"/>
      <path d="M71.6186 10.5585C71.8863 9.04701 72.7147 8.29168 74.1039 8.29168C75.4931 8.29168 76.3452 9.04701 76.5158 10.5585H71.6186ZM68.2803 12.07C68.2803 15.8002 70.5216 18.4084 74.3235 18.4084C76.8823 18.4084 78.9285 17.2627 79.6599 15.1902L76.8333 14.1662C76.4432 15.0686 75.5902 15.6287 74.5186 15.6287C72.8617 15.6287 71.8381 14.8489 71.5949 13.045H80.001V12.1427C80.001 8.12016 77.8813 5.43848 74.348 5.43848C70.8146 5.43848 68.2811 7.92499 68.2811 12.0692L68.2803 12.07Z" fill="black"/>
      <path d="M25.3872 3.51475C23.1215 1.24791 20.2029 0.0659016 16.9904 0H8.92632C3.95224 0.0659016 0 4.04365 0 9.01415C0 13.9847 4.04176 18.0283 9.00993 18.0283H16.9896C20.3127 18.0283 22.9314 15.3238 22.9314 11.9992C22.9314 8.67451 20.2282 5.97001 16.9051 5.97001H8.76418L7.58357 10.5071H16.9051C17.7277 10.5071 18.3965 11.1762 18.3965 11.9992C18.3965 12.8221 17.7277 13.4912 16.9051 13.4912H8.92548C6.45786 13.4912 4.53494 11.4829 4.53494 9.01415C4.53494 6.54538 6.45364 4.57678 8.92548 4.53707H16.9853C21.098 4.53707 24.3603 7.88453 24.3603 12C24.3603 16.1155 21.0144 19.4629 16.9009 19.4629H5.91316L4.73256 24H16.9904C20.1851 23.9392 23.1215 22.7521 25.3872 20.4853C27.653 18.2184 28.9003 15.2055 28.9003 12C28.9003 8.79448 27.653 5.7816 25.3872 3.51475Z" fill="#05DB4A"/>
      </g>
      <defs>
      <clipPath id="clip0_4380_37545">
      <rect width="80" height="24" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  </Container>
)

const Container = styled.div<{ clickable?: boolean }>`
  position: relative;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
`
